import {
  PlateEditor,
  TNodeEntry,
  unwrapNodes
} from "@udecode/plate";
import {
  Element,
  BaseElement,
  BaseEditor
} from "slate";

import { NodeType } from "../../types";

import { ELEMENT_SCENE } from "../createScenePlugin";

// collaboration_scene Unwrap scene nodes created in the collaboration environment
/**
 * should run only once per chapter when the user opens the chapter for the first time after migrating from the collaboration environment
 */
export const withScenes = <T extends PlateEditor>(editor: T): PlateEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    if (!Element.isElement(node)) return;

    if((node as (BaseElement | BaseEditor) & NodeType).type === ELEMENT_SCENE) {
      unwrapNodes(editor, { at: path });
    }

    normalizeNode([node, path]);
  };

  return editor;
};
