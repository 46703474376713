/* eslint-disable semi */
import { Chapter } from "../../types/chapter";
import { SceneMeta, SceneContent } from "../../types/scene";
import { ATNode, PlateNodes } from "../../components/Plate/config/typescript";
import { random } from "lodash";
import { initBody } from "../initials";

export class SceneUtils {
  /**
   * Create SlateOrnamentalBreak component with the given scene labels
   *
   * @param sceneLabels string[]
   * @returns SlateOrnamentalBreak
   */
  private static createOrnamentalBreakWithScenesMetadata(
    sceneLabels: string[]
  ): {
    type?: string | undefined;
    [key: string]: unknown;
    children: PlateNodes;
  } {
    return {
      type: "ornamental-break",
      id: random(100000, 999999),
      children: [{ type: "p", children: [{ text: "" }] }] as PlateNodes,
      sceneLabels: sceneLabels,
    };
  }

  /**
   * Get meta data list of scenes in a chapter
   *
   * @summary
   * - If the chapter has two or more scenes returns a list of SceneMeta
   * - Otherwise return an empty SceneMeta list
   *
   * @param chapter Chapter
   * @returns SceneMeta[]
   */
  static listScenes(chapter: Chapter): SceneMeta[] {
    const scenes: SceneMeta[] = [];
    let index = 0;

    for (const node of chapter.children) {
      if (node.type === "ornamental-break") {
        /** if its the first scene of the chapter which is above the first ob node */
        if (scenes.length === 0) {
          const collaborationAboveSceneTitle = node.aboveSceneTitle;
          const legacyAboveSceneTitle = node.sceneLabels?.length > 1 ? node.sceneLabels[1] : undefined;
          const firstScene: SceneMeta = {
            sceneName: (legacyAboveSceneTitle || collaborationAboveSceneTitle) || "Scene 1",
            sceneIndex: index,
          };
          index += 1;
          scenes.push(firstScene);
        }
        /** for the rest of the scenes of the chapter which are below the currently considered ob node */
        const collaborationBelowSceneTitle = node.belowSceneTitle;
        const legacyBelowSceneTitle = node.sceneLabels?.length > 0 ? node.sceneLabels[0]: undefined;
        const tmpScene: SceneMeta = {
          sceneName: (legacyBelowSceneTitle || collaborationBelowSceneTitle) || `Scene ${index + 1}`,
          sceneIndex: index,
        };
        index += 1;
        scenes.push(tmpScene);
      }
    }
    return scenes;
  }

  /**
   * Get scene content of a scene at a given index in a chapter
   *
   * @summary
   * - If the index is a valid index, return the scene content of that index
   * - Otherwise throws 'Index out of bound.' error
   *
   * @param chapter Chapter
   * @param index number
   * @returns SceneContent
   */
  static getSceneContent(chapter: Chapter, index: number): SceneContent {
    const sceneContent: SceneContent = { children: [] };
    let currentSceneIndex = 0;

    for (const node of chapter.children) {
      if (node.type === "ornamental-break") {
        currentSceneIndex += 1;
      } else if (currentSceneIndex === index) {
        sceneContent.children.push(node);
      }
    }

    if (index < 0 || currentSceneIndex < index) {
      throw new Error("Index out of bound.");
    }

    if (sceneContent.children.length === 0) {
      return { children: initBody };
    }

    return sceneContent;
  }

  /**
   * Insert a scene at the given index and return the new chapter object
   *
   * @summary
   * - If the index is the range 0 to number of children in chapter(both inclusive), insert the scene content at the given index
   * - Otherwise throws 'Index out of bound.' error
   * - When inserting a new scene a new ornamental-break is inserted and scene label is set as specified in meta. Scene labels of previous ornamental-break components are adjusted accordingly.
   *
   * @param chapter Chapter
   * @param index number
   * @param content SceneContent
   * @param meta SceneMeta
   * @returns Chapter
   */
  static insertScene(
    chapter: Chapter,
    index: number,
    content: SceneContent,
    meta: SceneMeta
  ): Chapter {
    const newChildren: ATNode = [];
    let currentSceneIndex = 0;
    const _chapter = { ...chapter };

    if (index === 0) {
      const sceneMetaList = this.listScenes(_chapter);
      const ob = this.createOrnamentalBreakWithScenesMetadata([
        sceneMetaList?.length > 0 ? sceneMetaList[0].sceneName : "Scene 2",
        meta.sceneName,
      ]);
      newChildren.push(...content.children, ob);
      currentSceneIndex += 1;
    }

    for (let i = 0; i < _chapter.children.length; i += 1) {
      let node = { ..._chapter.children[i] };
      if (node.type === "ornamental-break") {
        currentSceneIndex += 1;
        // Update sceneLabel after inserting to index 0
        if (index === 0 && currentSceneIndex === 2) {
          node = {
            ...node,
            sceneLabels: [
              node.sceneLabels?.length > 0 ? node.sceneLabels[0] : "Scene 3",
            ],
          };
        }

        if (currentSceneIndex === index) {
          const ob = this.createOrnamentalBreakWithScenesMetadata(
            index === 1
              ? [
                  meta.sceneName,
                  node.sceneLabels.length === 2
                    ? node.sceneLabels[1]
                    : "Scene 1",
                ]
              : [meta.sceneName]
          );
          newChildren.push(ob, ...content.children);
        }

        // Update sceneLabel after inserting to index 1
        if (index === 1 && currentSceneIndex === 1) {
          node = {
            ...node,
            sceneLabels: [
              node.sceneLabels?.length > 0 ? node.sceneLabels[0] : "Scene 3",
            ],
          };
        }
      }
      newChildren.push(node);
    }

    if (index === currentSceneIndex + 1) {
      currentSceneIndex += 1;
      const ob = this.createOrnamentalBreakWithScenesMetadata(
        index === 1 ? [meta.sceneName, "Scene 1"] : [meta.sceneName]
      );
      newChildren.push(ob, ...content.children);
    }

    if (index < 0 || index > currentSceneIndex + 1) {
      throw new Error("Index out of bound.");
    }

    return { ..._chapter, children: newChildren };
  }

  /**
   * Update scene content at the given index and return the new chapter object
   *
   * @summary
   * - If the index is valied, update the scene content at the given index
   * - Otherwise throws 'Index out of bound.' error
   *
   * @param chapter Chapter
   * @param index number
   * @param sceneContent SceneContent
   * @returns Chapter
   */
  static updateScene(
    chapter: Chapter,
    index: number,
    sceneContent: SceneContent
  ): Chapter {
    const newChildren: ATNode = [];
    let currentSceneIndex = 0;
    const _chapter = { ...chapter };
    if (index === 0) {
      newChildren.push(...sceneContent.children);
    }

    for (const node of _chapter.children) {
      if (node.type === "ornamental-break") {
        currentSceneIndex += 1;
        newChildren.push(node);
        if (currentSceneIndex === index) {
          newChildren.push(...sceneContent.children);
        }
      } else if (currentSceneIndex != index) {
        newChildren.push(node);
      }
    }

    if (index < 0 || index > currentSceneIndex) {
      throw new Error("Index out of bound.");
    }

    return { ..._chapter, children: newChildren };
  }

  /**
   * Update scene title of the scene at the given index and return the new chapter object
   *
   * @summary
   * - If the index is valied, update the scene title of the scene at the given index
   * - Otherwise throws 'Index out of bound.' error
   *
   * @param chapter Chapter
   * @param index number
   * @param title string
   * @returns Chapter
   */
  static updateSceneTitle(
    chapter: Chapter,
    index: number,
    title: string
  ): Chapter {
    const newChildren: ATNode = [];
    let currentSceneIndex = 0;
    const _chapter = { ...chapter };

    for (const _node of _chapter.children) {
      let node = { ..._node };
      if (node.type === "ornamental-break") {
        currentSceneIndex += 1;
        if (index === 0 && currentSceneIndex === 1) {
          if (
            node.sceneLabels?.length === 1 ||
            node.sceneLabels?.length === 2
          ) {
            node = { ...node, sceneLabels: [node.sceneLabels[0], title] };
          } else {
            node = { ...node, sceneLabels: ["Scene 1", title] };
          }
        } else if (index === 1 && currentSceneIndex === 1) {
          if (node.sceneLabels?.length === 2) {
            node = { ...node, sceneLabels: [title, node.sceneLabels[1]] };
          } else {
            node = { ...node, sceneLabels: [title, "Scene 1"] };
          }
        } else if (currentSceneIndex === index) {
          node = { ...node, sceneLabels: [title] };
        }
      }
      newChildren.push(node);
    }

    if (index < 0 || index > currentSceneIndex) {
      throw new Error("Index out of bound.");
    }

    return { ..._chapter, children: newChildren };
  }

  /**
   * Remove the scene at the given index and return the new chapter object
   *
   * @summary
   * - If the index is valied, remove the scene at the given index
   * - Otherwise throws 'Index out of bound.' error
   *
   * @param chapter Chapter
   * @param index number
   * @returns Chapter
   */
  static removeScene(chapter: Chapter, index: number): Chapter {
    const newChildren: ATNode = [];
    let currentSceneIndex = 0;
    let firstSceneLabel = "";
    const _chapter = { ...chapter };

    for (const _node of _chapter.children) {
      let node = { ..._node };
      if (node.type === "ornamental-break") {
        currentSceneIndex += 1;
        // Removing the first scene of a chapter
        if (index === 0 && currentSceneIndex === 1) {
          firstSceneLabel =
            node.sceneLabels?.length > 0 ? node.sceneLabels[0] : "Scene 1";
        }
        // Update second scene as first when removing the first scene of a chapter
        else if (index === 0 && currentSceneIndex === 2) {
          node = {
            ...node,
            sceneLabels: [
              node.sceneLabels?.length > 0 ? node.sceneLabels[0] : "Scene 1",
              firstSceneLabel,
            ],
          };
          newChildren.push(node);
        }
        // Removing the second scene of a chapter
        else if (index === 1 && currentSceneIndex === 1) {
          firstSceneLabel =
            node.sceneLabels?.length > 0 ? node.sceneLabels[1] : "Scene 1";
        }
        // Update third scene as second when removing the second scene of a chapter
        else if (index === 1 && currentSceneIndex === 2) {
          node = {
            ...node,
            sceneLabels: [...node.sceneLabels, firstSceneLabel],
          };
          newChildren.push(node);
        }
        // Removing index > 1
        /*eslint no-empty: "error"*/
        else if (index === currentSceneIndex) {
          // empty
        } else {
          newChildren.push(node);
        }
      } else if (currentSceneIndex != index) {
        newChildren.push(node);
      }
    }

    if (index < 0 || index > currentSceneIndex) {
      throw new Error("Index out of bound.");
    }

    return { ..._chapter, children: newChildren };
  }

  /**
   * Reconcile the scene labels of all the scenes of the chapter and return the new chapter object
   *
   * @summary
   * - If scene label is not present insert the scene name as 'Scene ${index}' where 'index' is the index of the scene counting from 1
   *
   * @param chapter Chapter
   * @returns Chapter
   */
  static reconcileScenes(chapter: Chapter): Chapter {
    const newChildren: ATNode = [];
    let currentSceneIndex = 0;
    const _chapter = { ...chapter };
    let oldFirstSceneLabel = "Scene 1";

    for (const node of _chapter.children) {
      if (node.type === "ornamental-break" && node.sceneLabels?.length === 2) {
        oldFirstSceneLabel = node.sceneLabels[1];
        break;
      }
    }

    for (const _node of chapter.children) {
      let node = { ..._node };
      if (node.type === "ornamental-break") {
        currentSceneIndex += 1;
        if (currentSceneIndex === 1) {
          if (node.sceneLabels?.length === 1) {
            node = {
              ...node,
              sceneLabels: [node.sceneLabels[0], oldFirstSceneLabel],
            };
          } else if (!node.sceneLabels || node.sceneLabels?.length === 0) {
            node = { ...node, sceneLabels: ["Scene 2", oldFirstSceneLabel] };
          }
        } else {
          if (!node.sceneLabels || node.sceneLabels?.length === 0) {
            node = { ...node, sceneLabels: [`Scene ${currentSceneIndex + 1}`] };
          } else {
            node = { ...node, sceneLabels: [node.sceneLabels[0]] };
          }
        }
      }
      newChildren.push(node);
    }

    return { ..._chapter, children: newChildren };
  }

  /**
   * Moves a scene within the chapter and returns the updated chapter
   * 
   * @param chapter chapter the scene belongs to 
   * @param fromIndex initial index of the scene to be moved
   * @param insertAfter scene will be moved to be after the given scene index (undefined when dropped as the first scene)
   * @returns updated chapter and scene meta
   */
  static moveScene(chapter: Chapter, fromIndex: number, insertAfter?: number): { chapter: Chapter, chapterScenes: SceneMeta[] } {

    chapter = SceneUtils.reconcileScenes(chapter);

    let sceneMeta = SceneUtils.listScenes(chapter);

    const sceneContent = SceneUtils.getSceneContent(chapter, fromIndex);
    const insertIndex = insertAfter !== undefined ? (fromIndex < insertAfter ? insertAfter : insertAfter + 1) : 0;

    chapter = SceneUtils.removeScene(chapter, fromIndex);
    chapter = SceneUtils.insertScene(chapter, insertIndex, sceneContent, sceneMeta[fromIndex]);

    if (sceneMeta.length === 2) {
      if (fromIndex === 0) {
        chapter = SceneUtils.updateSceneTitle(chapter, 0, sceneMeta[1].sceneName);
      }

      if (fromIndex === 1) {
        chapter = SceneUtils.updateSceneTitle(chapter, 1, sceneMeta[0].sceneName);
      }
    }

    sceneMeta = SceneUtils.listScenes(chapter);
    return { chapter, chapterScenes: sceneMeta }
  }

}
