import {
    PlateEditor,
    TNodeEntry,
    unsetNodes,
    withoutNormalizing,
    setNodes
  } from "@udecode/plate";
  import {
    Element,
    BaseElement,
    BaseEditor,
    Editor,
  } from "slate";
  
  import { NodeType } from "../../types";
  
  import { ELEMENT_ORNAMENTAL_BREAK } from "../createOrnamentalBreakPlugin";
  
  // collaboration_scene Reset the ob node strucutre to contain sceneLabels as an array
  /** 
   * should do the node manipulations only once per chapter, 
   * when the user opens the chapter for the first time after migrating from the collaboration environment
   * */
  export const withOrnamentalBreaks = <T extends PlateEditor>(editor: T): PlateEditor => {
    const { normalizeNode } = editor;
  
    editor.normalizeNode = ([node, path]: TNodeEntry) => {
      if (!Element.isElement(node)) return;
  
      if((node as (BaseElement | BaseEditor) & NodeType).type === ELEMENT_ORNAMENTAL_BREAK) {
        const aboveSceneTitle = node.aboveSceneTitle;
        const belowSceneTitle = node.belowSceneTitle;
        if(belowSceneTitle || aboveSceneTitle) {
            const hasObNodesAbove = Editor.previous(editor as BaseEditor, {at: path, match: n => (n as NodeType).type === ELEMENT_ORNAMENTAL_BREAK});
            withoutNormalizing(editor, () => {
                unsetNodes(editor, ["belowSceneTitle", "aboveSceneTitle"], { at: path });
                const sceneLabels = [belowSceneTitle];
                /** 
                 * if its the first ob node, it should have the scene titles of 
                 * both the scene above and below, under sceneLabels
                 * */
                if(!hasObNodesAbove) {
                    sceneLabels.push(aboveSceneTitle);
                }
                setNodes(editor, {
                    id: node.id,
                    type: node.type,
                    children: node.children,
                    sceneLabels: sceneLabels
                }, { at: path });
            });
        }
      }
  
      normalizeNode([node, path]);
    };
  
    return editor;
  };