import React, {useMemo, useEffect, useRef, useState, useCallback } from "react";
import { Row, Col } from "antd";
import {
    HeadingToolbar,
    Plate,
    focusEditor,
    PlateProvider,
    withoutNormalizing,
    TOperation,
    getOperations,
    createTEditor,
    withPlate,
    TNodeEntry,
    TNode,
    TRenderLeafProps,
} from "@udecode/plate";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { cloneDeep, debounce, throttle } from "lodash";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

//helpers
import { CONFIG } from "./config/config";
import { ATNode, MyEditor, MyRootBlock, PlateNodes } from "./config/typescript";
import { plugins } from "./configure";
import useRootStore from "../../store/useRootStore";
import Queue from "../../utils/queue";
import { SyncStatus } from "../../types/sync";
import useWebsocket from "../../utils/hooks/useWebsocket";

//components
import { Toolbar, WithEditorStyle, Titlebar } from "./partials";
import { Chapter } from "../../types/chapter";
import { SceneUtils } from "../../utils/scene/sceneServices";
import { getWordsCount, getAllSearchRanges, getNextSearchMatchStep, getSearchRanges, replaceAll, replaceOne } from "../../utils/helper";
import { Range } from "slate";
import { CHAPTER_TITLE_HIGHEST_SCALE } from "../../utils/config";
import { BottomBar } from "./partials/BottomBar";
import { EditorRightMenu } from "../Editor/RightMenu/EditorRightMenu";
import { MasterPageBottomBar } from "./partials/MasterPageBottomBar";
import { TrackChangesProperties } from "./plugins/track-changes/types";


const aws_io = process.env.REACT_APP_SYNC_AWS || "";

interface WebSocketPayload {
    action: string,
    id: string,
    children?: ATNode,
    ops: any[],
    sessionId: string
}

interface EditorProps {
  initialBodyValue: MyRootBlock[];
  sceneIndex: number | null;
}

const _Editor: React.FC<EditorProps> = ({ initialBodyValue, sceneIndex }) => {
    const { debouncedRefreshCache, refreshCache } = useRootStore().pdfCacheStore;
    const {
        getExpectOrnamentalBreakChange,
        setExpectOrnamentalBreakChange,
        getSceneLabelsInOrnamentalBreakAtDeletion } = useRootStore().sideMenuStore;
    const {
        sessionId,
        chapterTemplateView,
        writing,
        editor_setting,
        sidebarPreviewerOnlyMode,
        setMainChapterTemplate,
    } = useRootStore().appStore;

    const {
        book,
        chapter,
        debouncedSaveChapterBodyLocal,
        debounceUpdatelocalChapterLastSyncTime,
        debouncedSaveChapterTemplateBodyUpdates,
        debouncedSyncChapterBodyToServer,
        setWordsCount,
        body: b = [],
        putOfflineChapterRemote,
        setSaving,
        chapterHasOfflineContent,
        chapterSetOfflineContent,
        chapterTemplate: chapTemplate,
        sceneTitle,
        setSceneTitle,
        search,
        search_r,
        searchMatchedRanges,
        setSearchMatchedRanges,
        doSearchSetCount,
        searchStep,
        setSearchStep,
        updateSceneCache,
        updateSceneTitle,
        wordCountType
    } = useRootStore().bookStore;
    const term = useRef(search);
    const range = useRef(search_r);
    const [counter, setCounter] = useState(0);
    const { chapterTemplates } = useRootStore().chapterStore;

    const [isOfflineChapter, setIsOfflineChapter] = useState<boolean>(false);

    useEffect(() => {
        if (chapter._id.indexOf("offline") !== -1) {
            setIsOfflineChapter(true);
        }
    }, [chapter]);

    useEffect(() => {
        return () => {
            setMainChapterTemplate({
                _id: "",
                type: "uncategorized",
                title: "",
                titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
                image: "",
                children: []
            });
        };
    }, [chapTemplate._id]);

    // Chapter template library
    useEffect(() => {
        if (chapTemplate && chapTemplate._id) {
            //deselect(editor);
            chapterAutofocus = !chapTemplate.title || chapTemplate.title.length === 0 ? true : false;
            if (edtRef.current) {
                edtRef.current.scrollTo({
                    top: 0,
                    left: 0
                });
            }
        }
    }, [chapTemplate]);

    useEffect(() => {
        //deselect(editor);
        chapterAutofocus = !chapter.title || chapter.title.length === 0 ? true : false;

        if (edtRef.current) {
            edtRef.current.scrollTo({
                top: 0,
                left: 0
            });
        }
    }, [chapter._id]);

    const isMasterPage = useMemo(() => {
        return chapterTemplates.some(
          (template) => template.motherChapterId === chapter._id || template._id === chapter.templateId
        );
    }, [chapter._id, chapterTemplates]);

    const refreshPdfCache = useCallback(debounce(() => {
        refreshCache(chapter.bookId, "chapter-contents-change", { "chapter-contents-change": { chapterId: chapter._id, invalidateOnly: "current" } });
        debouncedRefreshCache(chapter.bookId, "chapter-contents-change", { "chapter-contents-change": { chapterId: chapter._id, invalidateOnly: "others"  } });
    },800),[chapter]);

    // Decide which field should be focused on initial load
    let chapterAutofocus = false;
    const editor = useMemo(() => withPlate(createTEditor() as MyEditor, {plugins}), []);
    const edtRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLSpanElement>(null);

    const remote = useRef(false);
    const sendOperationQueue = useRef(new Queue());

    const websocket = useWebsocket(`${aws_io}?chapterId=${chapter._id}`);

    if (websocket) {
        websocket.onmessage = (event) => {
            if (event.type === "message" && event.data) {
                const data = JSON.parse(event.data);
                handleOperations(data.ops, data.lastUpdateAt);
            }
        };
    }

    const handleOperations = async (operations: TOperation[], lastUpdatedAt: Date | undefined) => {
        if (operations) {
            remote.current = true;
            await applyOperations(operations);
            remote.current = false;
            debounceUpdatelocalChapterLastSyncTime(chapter._id, lastUpdatedAt);
        }
    };

    const applyOperations = (operations: TOperation[]) => {
        if (editor) {
            withoutNormalizing(editor, () => {
                operations.map((operation: any) => {
                    if (operation.sessionId !== sessionId) {
                        editor.apply(operation);
                    }
                });
            });
        }
        return Promise.resolve();
    };

    const isValidOperation = (operation?: any) => {
        if (operation) {
            return operation.type !== "set_selection" && !operation.data;
        }
    };

    const handleSceneTitleOnChange = (newTitle) => {
        if (sceneIndex !== null) {
            setSceneTitle(newTitle);
            updateSceneTitle(chapter._id, sceneIndex, newTitle);
            const updatedChapter = SceneUtils.updateSceneTitle({...chapter, children: b}, sceneIndex, newTitle);
            handleEditorOnChange(updatedChapter.children, true);
        }
    };

    const countSelectedWords = () => {
        if (wordCountType !== "selection") return;
        // set selected word count
        const selectedContent = editor.getFragment();
        if (selectedContent && selectedContent.length > 0 && selectedContent[0].children) {
            setWordsCount(getWordsCount(selectedContent));
        }
    };
   

    const handleEditorOnChange = (e, isSceneTitleUpdate = false) => {
        countSelectedWords();
        
        let event = cloneDeep(e);

        // Disabled AUTOMATIC Smartquotes From AT-168
        // if (event && event.length > 0) {
        //   event = applySmartQuotes(event);
        // }

        if (getExpectOrnamentalBreakChange()) {
            const sceneLabelsAtDeletion = getSceneLabelsInOrnamentalBreakAtDeletion();
            if (sceneIndex === null && sceneLabelsAtDeletion?.length === 2) {
                event = SceneUtils.updateSceneTitle({...chapter, children: event}, 0, sceneLabelsAtDeletion[1]).children;
            }
            updateSceneCache(chapter._id,{...chapter, children:event} as Chapter);
            setExpectOrnamentalBreakChange(false);
        }

        const operations = editor ? getOperations(editor) : [];

        const firstOperation = operations[0];
        if ( firstOperation && (firstOperation.type === "insert_text" || firstOperation.type === "remove_text")) {
            const ranges = getAllSearchRanges(editor, term.current);
            setSearchMatchedRanges(ranges);
        }

        doSearchSetCount();

        //check if it's chapter template
        if (chapterTemplateView && chapTemplate._id) {
            //save chapter template
            debouncedSaveChapterTemplateBodyUpdates(chapTemplate._id, event);
        } else {
            refreshPdfCache();

            let children = event;
            if (sceneIndex !== null && !isSceneTitleUpdate) {
                children = SceneUtils.updateScene({ ...chapter, children: b }, sceneIndex, { children: event }).children;
            }
            //save chapter otherwise
            debouncedSaveChapterBodyLocal(chapter._id, children, remote.current, isSceneTitleUpdate);
            if (!remote.current) {
                const timedOps = operations.map(operation => ({ ...operation, timestamp: Date.now(), sessionId }));
                sendOperationQueue.current.enqueue(timedOps);
                throttledSyncContent(event, chapter._id, sceneIndex, isSceneTitleUpdate);
            }

            //skip offline chapter
            if (isOfflineChapter) {
                return;
            }

            if (websocket?.readyState === WebSocket.OPEN && chapterHasOfflineContent(chapter._id)) {
                // putOfflineChapter(chapter._id, `${chapter.title} - OFFLINE`, v);
                putOfflineChapterRemote(chapter._id);
                chapterSetOfflineContent(chapter._id, false);
            }
            if (websocket?.readyState !== WebSocket.OPEN && !chapterHasOfflineContent(chapter._id)) {
                const hasValidOperations = operations.filter(isValidOperation).length > 0;
                if (hasValidOperations) {
                    chapterSetOfflineContent(chapter._id, true);
                }
            }
        }
    };

    const syncContent = (children: ATNode, id: string, sceneIndex: number|null = null, isSceneTitleUpdate = false) => {
        if (sceneIndex !== null && !isSceneTitleUpdate) {
            children = SceneUtils.updateScene({...chapter, children: b}, sceneIndex, {children: children}).children;
        }
        const ops = sendOperationQueue.current.dequeueMultiple(sendOperationQueue.current.getSize());
        const filteredOps = ops.filter(isValidOperation).sort(sortOpsByTimeStamp);
        const payload: WebSocketPayload = {
            action: "onmessage",
            id,
            children,
            ops: filteredOps,
            sessionId
        };
        const payloadSize = (new TextEncoder().encode(JSON.stringify(payload)).length) / 1024;
        if (payloadSize > 120) {
            debouncedSyncChapterBodyToServer(book._id, id, { children: children });
            delete payload.children;
        }
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify(payload));
            debouncedSetSaving();
        }
    };

    const throttledSyncContent = useCallback(throttle(syncContent, 3000), [sendOperationQueue.current, websocket]);

    const sortOpsByTimeStamp = (a, b) => a.timestamp - b.timestamp;

    /**
     *  A function to mimic the funcationality of 'saving' 'saved' messages
     *  showed in the editor when HTTPS was used to sync editor content instead
     *  of WSS
     *
     *  A setTimeout is used to mimic 'saving in progress' as WS.send is not async
     *  and returns a void
     */
    const debouncedSetSaving = useCallback(
        debounce(() => {
            if (websocket?.readyState === WebSocket.OPEN) {
                setSaving(SyncStatus.saving);
                setTimeout(() => {
                    setSaving(SyncStatus.saved);
                }, 1000);
            }
        }, 2000), [websocket?.readyState]);

    // Do autofocus and ScrollTo on Chapter change
    useEffect(() => {
        //deselect(editor);
        chapterAutofocus = !chapter.title || chapter.title.length === 0 ? true : false;

        if (edtRef.current) {
            edtRef.current.scrollTo({
                top: 0,
                left: 0
            });
        }
    }, [chapter._id]);

    // set offline chapter
    useEffect(() => {
        if (chapter._id.indexOf("offline") !== -1) {
            setIsOfflineChapter(true);
        }
    }, [chapter]);



    const decorate = React.useCallback(
        ([node, path]: TNodeEntry<TNode>) => getSearchRanges(node, path, term.current, toJS(range.current)) as unknown as Range[],
        [search, counter, chapter]
    );

    const renderLeafComponent = React.useCallback(
        ({ attributes, children, leaf }: TRenderLeafProps) => {
            const isInsertTrackChange =
                (leaf.trackChanges as TrackChangesProperties)?.operation === "insert" ||
                (leaf.trackChanges as TrackChangesProperties)?.operation === "update" ||
                false;
            let styles = {};
            if (leaf.select_highlight) {
                styles = {
                    padding: "0.25em",
                    borderRadius: 5,
                    backgroundColor: "#a0a4f0",
                    color: "#3a29b0",
                };
            }
            else if (leaf.all_highlight) {
                styles = {
                    padding: "0.25em",
                    borderRadius: 5,
                    backgroundColor: "#f6e58d",
                    color: "#eb4d4b",
                };
            }

            useEffect(() => {
                if(leaf.select_highlight){
                    selectRef.current?.scrollIntoView({
                        block: "end",
                        inline: "center"
                    });
                }
            }, [leaf.select_highlight]);

            return (
                !isInsertTrackChange ? (
                    <span
                        ref={leaf.select_highlight ? selectRef : null}
                        {...attributes}
                        {...(leaf.isFocusedSearchHighlight && {
                            "data-testid": "search-focused",
                        })}
                        style={styles}
                    >
                        {children}
                    </span>
                ) :
                <></>
            );
        },
    []);

    const handleReplace = (r: IBookStore.ReplaceParams) => {
        if (r.all) {
            replaceAll(editor, r.text, searchMatchedRanges);
        } else {
            replaceOne(editor, r.text, search_r);
        }
    };

    const handleEditorOnBlur = () => {
        if (wordCountType === "selection") {
            setWordsCount(0);
        }
    };

    useEffect(() => {
        countSelectedWords();
    }, [wordCountType]);


        //get and set all search ranges
        useEffect(() => {
            const ranges = getAllSearchRanges(editor, search);
            setSearchMatchedRanges(ranges);

            const step = getNextSearchMatchStep(editor, ranges);
            setSearchStep(step);

            term.current = search;
            range.current = searchMatchedRanges[searchStep];
        }, [search, editor, chapter]);

         //get and set all search ranges and update counter for re-rendering
         useEffect(() => {
            range.current = searchMatchedRanges[searchStep];
            setCounter(counter + 1);
        }, [searchMatchedRanges, searchStep]);


    return (
      <>
      <Row wrap={false} className="editor-wrapper">
        <Col flex={1} className="atticus-editor-container">
          <DndProvider backend={HTML5Backend}>
            <PlateProvider<PlateNodes>
              editor={editor}
              plugins={plugins}
              onChange={handleEditorOnChange}
              initialValue={initialBodyValue}
              normalizeInitialValue={true}
            >
              <Row className="editor-header">
                <Col span={24}>
                  <HeadingToolbar className="plate-toolbar-overrides">
                    <Toolbar />
                  </HeadingToolbar>
                </Col>
                <Col
                  className="editor-settings-toolbar-container"
                >
                </Col>
              </Row>
              <WithEditorStyle />
              <div className="editor-col">
                <div className="editor-area scroller" ref={edtRef}>
                  <Titlebar
                    onEnter={() => {
                      focusEditor(editor);
                    }}
                    customTitle={false}
                    placeholder={`${
                      sceneIndex !== null ? "Scene" : "Chapter"
                    } Title`}
                    autofocus={chapterAutofocus}
                    sceneIndex={sceneIndex}
                    handleSceneTitleOnChange={handleSceneTitleOnChange}
                    sceneTitle={sceneTitle}
                  />
                  <Plate<PlateNodes>
                    editableProps={{
                      ...CONFIG.editableProps,
                      decorate: decorate,
                      renderLeaf: renderLeafComponent,
                      onBlur: handleEditorOnBlur,
                    }}
                  ></Plate>
                </div>
              </div>
            </PlateProvider>
          </DndProvider>
          {isMasterPage || chapterTemplateView ? <MasterPageBottomBar/> : null}
          <BottomBar />
        </Col>
        {writing && editor_setting.show && !sidebarPreviewerOnlyMode && !chapterTemplateView ? (
            <Col id="sidebar" flex={editor_setting.view === "previewer" ? "" : "320px"} className="setting-area-wrapper">
                <EditorRightMenu 
                    view={editor_setting.view} 
                    doReplace={handleReplace}
                    editor = {editor}
                />
            </Col>
            ) : null}
      </Row>
      </>
    );
};

export const Editor = observer(_Editor);
