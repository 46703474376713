import { createPluginFactory } from "@udecode/plate-core";
import { SceneComponent } from "./components/sceneComponent";
import { withScenes } from "./normalizers/withScenes";

export const ELEMENT_SCENE = "scene";

export const createScenePlugin = createPluginFactory({
    key: ELEMENT_SCENE,
    type: ELEMENT_SCENE,
    isElement: true,
    component: SceneComponent,
    withOverrides: withScenes,
});