import React from "react";
import { observer } from "mobx-react";
import { RenderElementProps } from "slate-react";

export const SceneComponent: React.FC<RenderElementProps> = observer(
  ({ children, element }) => {
    return  (
      <div className="slate-scene">
        {children}
      </div>
    );
  }
);
